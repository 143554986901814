.utilityServices {
    padding: 10px;
}

.utilityServices a {
    width: 4rem;
    height: 4rem;
    line-height: 4rem !important;
    
    color: rgb(185, 80, 80);
  }

  .utilityServices div:nth-child(1) a {
    background: #ffd7d7 !important;
  }
  
  .utilityServices div:nth-child(2) a {
    background: #c9f2ff !important;
  }

  .utilityServices div:nth-child(3) a {
    background: #dbffe3 !important;
  }

  .utilityServices div:nth-child(4) a {
    background: #ffeab6 !important;
  }

.utilityServices .btn-floating .fas {
    width: 1.6rem !important;
    line-height: 1.8rem !important;
    font-size: 2rem !important;
}