.notification {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

/* TILE STYLES */

.notification .tile {
    margin-bottom: 5px;
    padding: 10px 20px !important;
    background-color: #fff;
    box-shadow: 3px 3px 5px 0px rgba(153,153,153,0.75);
    width: 200px; 
    display: inline-block;
    border-radius: 10px;
    color: #333;
    font-size: .8rem;
}

.notification .tile h6{
    font-size: .8rem
}

.notification .tile .btn-floating {
    width: 3rem;
    height: 3rem;
    background: #fff !important;
  }

  .notification .tile .btn-floating .fas {
    width: 1.6rem !important;
    line-height: 1.8rem !important;
    font-size: 1.5rem !important;
}  

.notification .icon {
    padding: 0 10px;
}

.notification .icon i{
    color: #e35249;
    font-size: 1.8rem
}


/* VIEW MORE */

.notification .notificationViewMore {
    background: #fff;
}

.notification .notificationViewMore a {
    color: #e35249 !important;
}
