.addEditPropertyForm input{
    background: #f5f5f5 !important;
    border: 0px !important;
}

.addEditPropertyForm .cCode {
    /* border: 1px solid #5f017d !important; */
    border: 1px solid rgba(95, 1, 125, .2) !important;
}

.addEditPropertyForm .spBlock {
    background-color: #fbf1ff;
}



.addEditPropertyForm select {
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #c3c3c3;   
}

.addEditPropertyForm i {
    margin: 5px 8px;
    font-size: 1.1rem;
    color: #5f017d
}

.addEditPropertyForm .btn i { margin-left: 0}


.addEditPropertyForm .view { background: #f5f5f5; }
.addEditPropertyForm .view a{
    padding: 7px 50px;
    color: #000;
    font-size: .9rem !important
}

.addEditPropertyForm .view select{ 
    background: transparent !important;
    width: 100% !important;
    font-size: .9rem !important
}

.addEditPropertyForm .phoneNum{
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    padding: 0.4rem;
}