
/* Main Title    */

/* Sub Title */

.tileTitle {
    font-size: .9rem;
}

.alertStyles {
    padding: 5px 10px;
    position: fixed;
    width: 95%;
    margin: 0 auto;
    z-index: 999;
    
}

.closeAleft {
    color: #fff;
    position: absolute;
    z-index: 99 ;
    top: 18px;
    right: 20px;
}

.success, .warning, .error, .info {
    padding: 15px 20px;
    border-radius: .3rem;
    box-shadow: 2px 1px 15px #444;
}

.success {
    background-color: #9aebbb;
    color: #006e2c;
}

.warning {
    background-color: #edd7ab;
    color: #640;
}

.error {
    background-color: #efbfc7;
    color: #951d32;
}

.info {
    background-color: #a9dff1;
    color: #22738e;
}

.closeAleft {
    color: #fff
}
