.communications {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

/* TILE STYLES */

.communications .tile {
    margin-bottom: 5px;
    padding: 10px 25px !important;
    background-color: #fff;
    box-shadow: 3px 3px 5px 0px rgba(153,153,153,0.75);
    width: 200px;
    display: inline-block;
    border-radius: 10px;
    color: #333;
    font-size: .8rem;
}

.communications .tile small.title{ 
    color: #e5726a;
    font-weight: bold;
}

.communications .tile h6{
    font-size: .8rem
}

.communications .tile p{
    margin-bottom: .6rem
}

.communications .tile .btn-floating {
    width: 3rem;
    height: 3rem;
    background: #fff !important;
}

.communications .tile .actions a {
    color: #777
}

.communications .tile .actions .fas {
    color: #e1473d;
    font-size: .9rem
}  

.communications .icon i{
    color: #0096b4;
    font-size: 1.2rem
}


/* VIEW MORE */

.communications .communicationsViewMore {
    background: #fff;
}

.communications .communicationsViewMore a {
    color: #0096b4 !important;
}
