.society i, .executive i {
    color: #e1473d;
    font-size: 1.3rem;
}

.bankDetails i {
    color: #747fe0
}
.comitte i {
    color: #444;
}

.balance {
    background-color: #747fe0;
    color: #fff;
}

.bankDetails .accType {
    background-color: #fe7062 !important;
}

.executive .name {
    color: #747fe0
}

.executive .avatar {
    width: 4rem;
    height: 4rem;
    background-color: #747fe0 !important;
}

.executive .avatar i {
    font-size: 2.5rem;
}

.horizontalScroll {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

.selectBlock {
    box-shadow: 0px 4px 5px rgb(206 206 206);
    padding: 20px 10px !important;
}

.selectBlock .inActiveBtn{
    border: 1px solid #ddd;
    color: #000;
}

.selectBlock .btn.inActiveBtn {
    background-color: #fff !important;
} 