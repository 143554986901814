.loginPage .form-outline .trailing {
  /* font-size: 20px !important; */
  color: #5fb62a;
}
.inline {
  max-width: 400px;
  margin: auto;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-bottom: 3px;
}
