.rentSale {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

.rentSale .facilities{
    cursor: pointer;
    display: inline-block;
}

/* TILE STYLES */

.rentSale .tile {
    margin-right: 10px !important;
    padding: 10px 15px !important;
    background-color: #fff;
    box-shadow: 3px 3px 5px 0px rgba(153,153,153,0.75);
    display: inline-block;
    border-radius: 10px;
    color: #333;
    font-size: .8rem;
}

.rentSale .tile h6{
    font-size: .8rem;
    color: #0448c7;
    margin: 5px 0 3px !important;
}

.rentSale .tile small{ 
    font-size: .8rem;
}
  
  .rentSale img {
    width: 180px;
  }
