.createAccount .form-outline .trailing {
    left: 10px;
    right: 0px !important;
    font-size: 18px !important;
}

.createAccount input.form-control {
    padding-left: 35px !important;
    padding-right: 10px !important;
}

.createAccount .form-outline .form-control~.form-label {
    padding-left: 25px !important;
}