.myPropertyInfo .box {
    width: 90%;
    margin: auto
}

.myPropertyInfo .flatBox {
    background-color: #C9F2FF;
}

.myPropertyInfo .comitteeBox {
    background-color: #A0EEC8;
}

.myPropertyInfo .societyBox {
    background-color: #52D7F1;
}

.myPropertyInfo .bankBox {
    background-color: #C1C7FD;
}

.myPropertyInfo .documentBox {
    background-color: #FFEAB6;
}

.myPropertyInfo .communicationsBox {
    background-color: #CDE8FE;
}

.myPropertyInfo i {
    color: #505050
}