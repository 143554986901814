.flats .search {
    background: #e1e1e1;
}

.flats .filterTabs {
    position: relative;
    top: -25px;
    z-index: 999;
    max-width: 1000px;
    overflow: hidden;
    white-space: nowrap;
}

.flats .filterTabs div {
    position: relative;
    box-shadow: 3px 3px 5px #ddd;
    background: #f5f5f5;
    /* border: 1px solid #d5d5d5; */
    padding: 7px 15px;
    margin: 0 5px 0 0;
    
}

.flats .filterTabs div:hover {
    background-color: #fe7062;
    color: #fff
}

.flats .propertyListItem {
    background-color: #f5f5f5;
}

.flats .propertyListItem .fa-home {
    color: #fe7062
}

.flats .selectedBlock {
    background-color: #fe7062 !important;
    color: #fff
}

.roads {
    color: #fe7062;
    padding: 5px 10px;
    position: relative;
    top: -25px;
}

.roads span {
    /* color: #fff; */
    display: inline-block;
    margin: 0 10px;
    padding: 5px;
    padding-inline: 10px;
}