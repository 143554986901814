body {
  margin: 0;
  /* font-family: Roboto, Helvetica, Arial, sans-serif;
   */
   font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar, .footer {
  background-color: #5f017d !important;
}

.navbar, .navbar-brand, .navbar, .fa-bars {
  color: #fff;
}

 .carousel {
  background: #fbedff;
}

.newVisitor .visitorInfo small{
  font-size: .7rem;
  
}

.newVisitor .visitorInfo h6{
  font-size: .9rem;
  font-weight: 900;
  line-height: .7rem;
}

.newVisitor .btn-floating {
  width: 3rem;
  height: 3rem;
}

.newVisitor .btn-floating .fas {
  width: 1.6rem !important;
  line-height: 1.8rem !important;
  font-size: 1.5rem !important;
}

.btn {
  line-height: 1 !important;
  padding: .5rem .8rem !important;
  background-color: #5f017d !important;
}

.btn:hover {
  background-color: #8D15B4 !important;
}

.btn i{ color: #fff !important }

input.form-control {
  border: 1px solid #ddd !important
}

/* .carousel-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 40px 0px;
  padding: 0 50px 0;
}

.itemImg img{
  width: 120px; 
  height: 60px;
  margin-right: 10px
}
.caption {
  display: flex;
  justify-content: center;
  align-items: start;
  color: #000
}

.caption .icon {
  font-size: 1.2rem;
  color:#5f017d;
}

.caption .info {
  margin-left: 5px;
}
.caption h5{
  text-transform: uppercase;
  margin: 0px !important;
  font-size: .8rem;
}

.caption small{ 
  font-size: .8rem;
}

.carousel-indicators {
  display: none !important;
}


@media (max-width: 576px) {
  .carousel-caption {
      display: block !important;
  }
}  */


.form-outline .trailing {
  color: #C4C4C4;
}

.backLink {
  color: #fff !important;
  font-size: larger;
}