.advertisements {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

.advertisements .advertisementsViewMore {
    background: #1b75bc
}

/* TILE STYLES */

.advertisements .tile {
    width: 200px; height: 100px; display: inline-block;
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    text-align: center;
}

.advertisements .tile h6 {
    font-size: 1.3rem;
  }

.advertisements .tile .btn-floating .fas {
    width: 1.6rem !important;
    line-height: 1.8rem !important;
    font-size: 1.5rem !important;
}  

.advertisements .tile a{
    color: #fff;
    font-size: .7rem
}