.otp input {
  height: 60px;
  padding: 10px;
  text-align: center;
  background: #f3f3f3;
  border: 0px;
  box-shadow: 5px 5px 5px #999;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.5rem;
}

.otp .phoneNumber,
.otp .title {
  color: #5f017d;
}
