.propertyBanner {
    background-color: #FBEDFF;
}
.propertyBanner .itemImg {
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-shadow: 3px 3px 5px #ccc;
    margin-right: 10px;
    width: 115px;
    height: 60px;
}
.propertyBanner .itemImg img{
    position: absolute;
    left: 7px;
    top: -15px;
    width: 100px; 
    height: 55px;
  }
.propertyBanner .caption {
    display: flex;
    justify-content: center;
    align-items: start;
    color: #000
  }
  
.propertyBanner .caption .icon {
    font-size: 1.3rem;
    color:#5f017d;
  }
  
.propertyBanner .caption .info {
    margin-left: 10px;
  }
.propertyBanner .caption h5{
    text-transform: uppercase;
    margin: 0px !important;
    font-size: .8rem;
  }
  
.propertyBanner .caption small{ 
    font-size: .8rem;
  }