.clientDues {
    max-width: 1000px;
    overflow: auto;
    white-space: nowrap;
    padding: 0px !important;
    margin: 0px !important;
}

.clientDues .clientDuesViewMore {
    background: #1b75bc
}

/* TILE STYLES */

.clientDues .tile {
    padding: 10px 20px !important;
    width: 175px; 
    max-width: 200px; 
    display: inline-block;
    border-radius: 10px;
    color: #fff;
    font-size: .8rem;
}

.clientDues .tile .btn-floating {
    width: 3rem;
    height: 3rem;
    background: #fff !important;
  }

.clientDues .tile .btn-floating .fas {
    width: 1.6rem !important;
    line-height: 1.8rem !important;
    font-size: 1.5rem !important;
}  

.clientDues .tile a{
    color: #fff;
    font-size: .7rem
}

.clientDues .visitorInfo {
    text-align: right;
}